import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import assets from "../../assets/index";
import { setUser } from "../../redux/features/userSlice";
import HelpIcon from "@mui/icons-material/Help";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Tooltip,
  Grid,
} from "@mui/material";
import moodApi from "../../api/moodApi";
import noteApi from "../../api/noteApi";
import authApi from "../../api/authApi";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import dayjs from "dayjs";
import Avatar from "@mui/material/Avatar";
import UserModal from "../../components/common/UserModal";

let timer;
const timeout = 500;
const Label = styled("label")`
  padding: 0 0 4px;
  margin-top: 10px;
  line-height: 1.5;
  display: block;
  text-align: center;
`;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = (props) => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState(undefined);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [openDialogLogout, setOpenDialogLogout] = useState(false);
  const [moods, setMoods] = useState([]);
  const [moodErrText, setMoodErrText] = useState("");
  const notes = props.notes;
  const [notesCount, setNotesCount] = useState(props.notesCount);
  const latitude = props.latitude;
  const longitude = props.longitude;

  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const openMenuDashboard = Boolean(anchorElNotification);
  const handleClick = (event) => {
    setAnchorElNotification(event.currentTarget);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await noteApi.updateReadCount(user.id);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setNotesCount(0);
  };
  const handleClose = () => {
    setAnchorElNotification(null);
  };

  useEffect(() => {
    const getMoods = async () => {
      try {
        const res = await moodApi.getAll();
        setMoods(res);
      } catch (err) {
        console.log(err);
      }
    };
    getMoods();
  }, []);

  const onUpdateUser = (user) => {
    dispatch(setUser(user));
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    setMoodErrText("");

    const data = new FormData(e.target);
    const mood = data.get("mood");
    const userId = user.id;

    let err = false;

    if (mood === null) {
      err = true;
      setMoodErrText("Please fill in your mood below");
    }

    if (err) return;

    try {
      await authApi.logout({ userId, mood, latitude, longitude });
      localStorage.removeItem("token");
      navigate("/login");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "mood") {
          setMoodErrText(e.msg);
        }
      });
    }
  };

  const showDialogLogout = () => {
    setOpenDialogLogout(true);
  };

  const hideDialog = () => {
    setOpenDialogLogout(false);
  };

  // const logout = () => {
  //   localStorage.removeItem("token");
  //   navigate("/login");
  // };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setSelectedUser(user);
        }}
      >
        Edit Profile
      </MenuItem>
      <MenuItem onClick={showDialogLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    // <Menu
    //   anchorEl={mobileMoreAnchorEl}
    //   anchorOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   id={mobileMenuId}
    //   keepMounted
    //   transformOrigin={{
    //     vertical: "top",
    //     horizontal: "right",
    //   }}
    //   open={isMobileMenuOpen}
    //   onClose={handleMobileMenuClose}
    // >
    //   {/* <MenuItem>
    //     <IconButton size="large" aria-label="show 4 new mails" color="inherit">
    //       <Badge badgeContent={4} color="error">
    //         <MailIcon />
    //       </Badge>
    //     </IconButton>
    //     <p>Messages</p>
    //   </MenuItem>
    //   <MenuItem>
    //     <IconButton
    //       size="large"
    //       aria-label="show 17 new notifications"
    //       color="inherit"
    //     >
    //       <Badge badgeContent={17} color="error">
    //         <NotificationsIcon />
    //       </Badge>
    //     </IconButton>
    //     <p>Notifications</p>
    //   </MenuItem> */}
    //   <MenuItem onClick={handleProfileMenuOpen}>
    //     <IconButton
    //       size="large"
    //       aria-label="account of current user"
    //       aria-controls="primary-search-account-menu"
    //       aria-haspopup="true"
    //       color="inherit"
    //     >
    //       <AccountCircle />
    //     </IconButton>
    //     <p>Profile</p>
    //   </MenuItem>
    // </Menu>
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          setSelectedUser(user);
        }}
      >
        Edit Profile
      </MenuItem>
      <MenuItem onClick={showDialogLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      {openDialogLogout && (
        <Dialog
          open={openDialogLogout}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            component="form"
            sx={{ mt: 1 }}
            onSubmit={handleLogout}
            noValidate
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography
                  fontWeight="700"
                  sx={{ fontSize: "16px", textAlign: "center", mt: 1 }}
                >
                  What's your mood?
                  {moodErrText !== "" && (
                    <span
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      <br />
                      {moodErrText}
                    </span>
                  )}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {moods.map((mood, index) => (
                    <Label key={index}>
                      <input
                        className="mood"
                        type="radio"
                        name="mood"
                        value={mood.id}
                      />
                      <span style={{ fontSize: "40px" }}>{mood.icon}</span>
                      <br />
                      <span style={{ fontSize: "12px", marginTop: "-3px" }}>
                        {mood.name}
                      </span>
                    </Label>
                  ))}
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                Logout
              </Button>
              <Button onClick={hideDialog} color="primary">
                No
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor:
              theme.palette.mode === "dark"
                ? assets.colors.headerDarkBg
                : assets.colors.headerLightBg,
            backgroundImage: "unset",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.light
                : assets.colors.secondary,
            boxShadow: "none",
            borderBottom:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Toolbar sx={{ minHeight: "64px" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? assets.images.logoDark
                    : assets.images.logoLight
                }
                style={{ width: "90px", marginRight: "10px" }}
                alt="app logo"
              />
              SCRUM BOARD
            </Typography>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "flex", sm: "none" }, alignItems: "center" }}
            >
              <img
                src={
                  theme.palette.mode === "dark"
                    ? assets.images.logoDark
                    : assets.images.logoLight
                }
                style={{ width: "90px", marginRight: "10px" }}
                alt="app logo"
              />
            </Typography>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ ml: 2 }}
              onClick={props.toggleSidebar}
            >
              <MenuIcon />
            </IconButton>
            <Search
              sx={{
                display: { xs: "none", sm: "flex" },
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.secondary
                    : "1px solid " + assets.colors.borderLightColor,
              }}
            >
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                onChange={(e) => props.searchTask(e)}
                value={props.searchQuery}
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ gap: 1, display: { xs: "flex", md: "flex" } }}>
              {/* <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton> */}
              <Tooltip title="Notification">
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  id="basic-button"
                  aria-controls={openMenuDashboard ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuDashboard ? "true" : undefined}
                  onClick={handleClick}
                >
                  {notesCount !== 0 ? (
                    <Badge badgeContent={notesCount} color="error">
                      <NotificationsIcon />
                    </Badge>
                  ) : (
                    <>
                      <NotificationsIcon />
                    </>
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorElNotification}
                open={openMenuDashboard}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {notes.map((note, index) => (
                  <>
                    {index <= 4 && (
                      <MenuItem
                        key={index}
                        component={Link}
                        to={`/dashboard/company_roadmaps/${note.id}`}
                        sx={{ fontSize: "14px" }}
                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "normal",
                          }}
                        >
                          <Grid item xs={8} sm={8}>
                            {note.icon} {note.name}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sm={4}
                            sx={{
                              display: "flex",
                              fontWeight: 300,
                              justifyContent: "flex-end",
                            }}
                          >
                            {dayjs(new Date(note.createdAt)).format(
                              "YYYY-MM-DD"
                            )}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    )}
                  </>
                ))}
                <MenuItem
                  component={Link}
                  sx={{
                    pl: 10,
                    pr: 10,
                    mt: 1,
                    fontSize: "12px",
                    fontWeight: "300",
                    // borderTop: "1px solid #FFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  to={`/dashboard/company_roadmaps/all`}
                >
                  Show All Notifications
                </MenuItem>
              </Menu>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => props.toggleMode()}
              >
                {props.mode === "dark" ? (
                  <Brightness5Icon />
                ) : (
                  <ModeNightIcon />
                )}
              </IconButton>

              <IconButton size="small" color="inherit">
                {/* <a
                  href={assets.help.docx}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{color: "unset", fontSize: 12}}
                >
                  <HelpIcon />
                </a> */}
                <a
                  href={assets.help.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "unset", fontSize: 12 }}
                >
                  <HelpIcon />
                </a>
              </IconButton>
              {/* <Button
                onClick={showDialogLogout}
                // onClick={logout}
                variant="contained"
                disableElevation={true}
                size="small"
                color="error"
                sx={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <LogoutOutlinedIcon fontSize="small" /> &nbsp;Logout
              </Button> */}
              <Avatar
                alt={user.name}
                src={user.image}
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{
                  cursor: "pointer!important",
                  marginTop: "3px",
                  "&:hover": {
                    opacity: 0.85,
                    backgroundColor: "#000",
                  },
                }}
              />
              {/* <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton> */}
            </Box>
            <Box sx={{ display: { xs: "none", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <UserModal
        user={selectedUser}
        onClose={() => setSelectedUser(undefined)}
        onUpdate={onUpdateUser}
        positions="null"
        roles="null"
        employeeTypes="null"
        workStatuses="null"
      />
    </>
  );
};

export default Header;
