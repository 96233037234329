import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import Sidebar from "../common/Sidebar";
import Header from "../common/Header";
import { setUser } from "../../redux/features/userSlice";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import assets from "../../assets/index";
import noteApi from "../../api/noteApi";
import cronApi from "../../api/cronApi";
import userApi from "../../api/userApi";

import '../../css/font.css';
import '../../css/responsive.css';
import "../../css/confetti.css";
import Moment from "moment";

const AppLayout = () => {
  if (localStorage.getItem("mode") === null) {
    localStorage.setItem("mode", "dark");
  }
  const theme = createTheme({
    palette: { mode: localStorage.getItem("mode") },
  });
  const user = useSelector((state) => state.user.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [latitude, setLatitude] = useState(user.latitude);
  const [longitude, setLongitude] = useState(user.longitude);

  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState([]);
  const [notesCount, setNotesCount] = useState(0);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isOpenToggleSidebar, setIsOpenToggleSidebar] = useState(true);
  const [sidebarWidth, setSidebarWidth] = useState(220);
  const [mode, setMode] = useState(localStorage.getItem("mode"));

  const toggleSidebar = () => {
    if (isOpenToggleSidebar === true) {
      setIsOpenToggleSidebar(false);
      setSidebarWidth(0);
    } else {
      setIsOpenToggleSidebar(true);
      setSidebarWidth(200);
    }
  };

  const searchTask = async (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleMode = async (e) => {
    if (mode === "dark") {
      localStorage.setItem("mode", "light");
      setMode("light");
      theme.palette.mode = "light";
    } else {
      localStorage.setItem("mode", "dark");
      setMode("dark");
      theme.palette.mode = "dark";
    }
  };

  useEffect(() => {
    if(innerWidth < 768){
      setIsOpenToggleSidebar(false);
      setSidebarWidth(0);
    }
    document.title = "Zabran Scrum Board";
    const checkAuth = async () => {
      const resCron = await cronApi.getLoginCheck();
      const user = await authUtils.isAuthenticated();
      if (!user) {
        navigate("/login");
      } else {
        const resCronOne = await cronApi.getLoginCheckOne(user.id);
        setLatitude(user.latitude);
        setLongitude(user.longitude);
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            if (position.coords.latitude !== null) {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
              console.log(user.latitude.toString())
              console.log(position.coords.latitude.toString())
              if (
                position.coords.latitude.toString() !== user.latitude.toString()
              ) {
                let timer;
                timer = setTimeout(async () => {
                  try {
                    await userApi.update(user.id, {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    });
                  } catch (err) {
                    console.log(err);
                  }
                }, 0);
              }
            }
          });
          // console.log(positionLatLong);
        } else {
          // console.log("Geolocation is not available in your browser.");
        }
        // save user
        dispatch(setUser(user));
        const res = await noteApi.getNotification(user.id);
        setNotes(res.jsonNotes);
        setNotesCount(res.notesCount);
        setLoading(false);
      }
    };
    checkAuth();

    // const initialValue = document.body.style.zoom;

    // // Change zoom level on mount
    // document.body.style.zoom = "90%";

    // return () => {
    //   // Restore default value
    //   document.body.style.zoom = initialValue;
    // };
  }, [navigate]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    <ThemeProvider theme={theme}>
      {Moment(user.birth).format("DD MM") === Moment().format("DD MM") && (
        <div className="confetti-container">
          <div className="confetti">
            <i
              style={{ "--speed": 20, "--bg": "yellow" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "white" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 29, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "blue" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 33, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 26, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 24, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 5, "--bg": "blue" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 40, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 25, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 15, "--bg": "yellow" }}
              className="wavy-line"
            >
              {" "}
            </i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 25, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 23, "--bg": "pink" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "pink" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 36, "--bg": "white" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "green" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 29, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 30, "--bg": "pink" }}
              className="rectangle"
            ></i>
            <i style={{ "--speed": 30, "--bg": "red" }} className="square"></i>
            <i
              style={{ "--speed": 18, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 16, "--bg": "blue" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 23, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 34, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 39, "--bg": "pink" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 40, "--bg": "purple" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 21, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 14, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 38, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 29, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 21, "--bg": "white" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "purple" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 23, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 48, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 38, "--bg": "pink" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 13, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 49, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "cyan" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 15, "--bg": "steelblue" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 20, "--bg": "yellow" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "white" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 29, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "blue" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 33, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 26, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 24, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            {/* <i style={{"--speed": 5, "--bg": "white"}} className="wavy-line"></i> */}
            <i
              style={{ "--speed": 40, "--bg": "purple" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 25, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i style={{ "--speed": 15, "--bg": "cyan" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 45, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 37, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 26, "--bg": "white" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "cyan" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 45, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 50, "--bg": "pink" }}
              className="rectangle"
            ></i>
            <i style={{ "--speed": 30, "--bg": "red" }} className="square"></i>
            <i
              style={{ "--speed": 18, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 16, "--bg": "blue" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 23, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 33, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 39, "--bg": "white" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 40, "--bg": "orange" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 21, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 14, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 38, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 29, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 34, "--bg": "white" }}
              className="hexagram"
            ></i>
            <i
              style={{ "--speed": 17, "--bg": "indigo" }}
              className="wavy-line"
            ></i>
            <i
              style={{ "--speed": 32, "--bg": "yellow" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 23, "--bg": "white" }}
              className="square"
            ></i>
            <i
              style={{ "--speed": 18, "--bg": "green" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 37, "--bg": "red" }}
              className="dodecagram"
            ></i>
            <i style={{ "--speed": 48, "--bg": "pink" }} className="wavy-line">
              {" "}
            </i>
            <i
              style={{ "--speed": 38, "--bg": "pink" }}
              className="rectangle"
            ></i>
            <i
              style={{ "--speed": 13, "--bg": "red" }}
              className="pentagram"
            ></i>
            <i
              style={{ "--speed": 49, "--bg": "yellow" }}
              className="dodecagram"
            ></i>
            <i
              style={{ "--speed": 19, "--bg": "purple" }}
              className="wavy-line"
            ></i>
            <i style={{ "--speed": 15, "--bg": "cyan" }} className="square"></i>
          </div>
        </div>
      )}
      <Header
        toggleSidebar={toggleSidebar}
        toggleMode={toggleMode}
        mode={mode}
        latitude={latitude}
        longitude={longitude}
        searchQuery={searchQuery}
        searchTask={searchTask}
        notes={notes}
        notesCount={notesCount}
      />
      <Box
        sx={{
          display: "flex",
          minHeight: "105vh",
          marginTop: "65px",
        }}
      >
        <Sidebar sidebarWidth={sidebarWidth} />
        <Box
          sx={{
            flexGrow: 1,
            p: 0,
            // width: "max-content",
            width: "1px",
            background:
              theme.palette.mode === "dark"
                ? assets.colors.mainDarkBg
                : assets.colors.mainLightBg,
          }} className="box-outlet"
        >
          <Outlet context={searchQuery} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AppLayout;
