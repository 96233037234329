import { Backdrop, Fade, Modal, Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Moment from "moment";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "50%",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "90%",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const UserLogModal = (props) => {
  const theme = useTheme();

  const [user, setUser] = useState(props.user);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setUser(props.user);
    setRows(props.user !== undefined ? props.user.userMoods : []);
    if (props.user !== undefined) {
      isModalClosed = false;
    }
  }, [props.user]);

  const onClose = () => {
    isModalClosed = true;
    props.onClose();
  };

  return (
    <>
      <Modal
        open={user !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={user !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Log Activity
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onClose();
                }}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="center">Mood Icon</TableCell>
                    <TableCell align="left">Mood Name</TableCell>
                    <TableCell align="left">Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows &&
                    rows.length !== 0 &&
                    rows.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {Moment(row.createdAt)
                            .locale("id")
                            .format("DD MMM YYYY LT")}
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="center" sx={{ fontSize: "16px" }}>
                          {row.mood.icon}
                        </TableCell>
                        <TableCell align="left">{row.mood.name}</TableCell>
                        <TableCell align="left">
                          {row.latitude !== null && row.latitude !== "" && (
                            <span>
                              {row.latitude},{row.longitude}
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserLogModal;
