import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import boardApi from "../../api/boardApi";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import Moment from "moment";
import { pink, blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { setBoards } from "../../redux/features/boardSlice";
import { setFavouriteList } from "../../redux/features/favouriteSlice";
import { useDispatch, useSelector } from "react-redux";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "95%",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "95%",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const BoardBackgroundModal = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [fileURL, setFileURL] = useState(null);

  const [board, setBoard] = useState(props.board);
  const [boardId, setBoardId] = useState("");
  const [icon, setIcon] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isFavourite, setIsFavourite] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [boardType, setBoardType] = useState("");
  const [background, setBackground] = useState("");
  const boardTypes = props.boardTypes;

  const boards = useSelector((state) => state.board.value);
  const favouriteList = useSelector((state) => state.favourites.value);

  useEffect(() => {
    setFileURL(null);
    setBoard(props.board);

    setBoardId(props.board !== undefined ? props.board.id : "");
    setIcon(props.board !== undefined ? props.board.icon : "");
    setTitle(props.board !== undefined ? props.board.title : "");
    setDescription(props.board !== undefined ? props.board.description : "");
    setIsFavourite(props.board !== undefined ? props.board.favourite : "");
    setStartDate(
      props.board !== undefined ? dayjs(props.board.startDate) : dayjs(Moment())
    );
    setDueDate(
      props.board !== undefined ? dayjs(props.board.dueDate) : dayjs(Moment())
    );
    setBoardType(
      props.board !== undefined
        ? props.board.boardType !== undefined
          ? props.board.boardType.id
          : ""
        : ""
    );

    setBackground(props.board !== undefined ? props.board.background : "");
    if (props.board !== undefined) {
      isModalClosed = false;
    }
  }, [props.board]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(board);
    props.onClose();
  };

  const updateIsFavourite = async (e) => {
    console.log(e);
    try {
      const favourite = e.target.value;
      const updatedBoard = await boardApi.update(boardId, {
        favourite: favourite,
      });
      let newFavouriteList = [...favouriteList];
      if (favourite) {
        newFavouriteList = newFavouriteList.filter((e) => e.id !== boardId);
      } else {
        newFavouriteList.unshift(updatedBoard);
      }
      dispatch(setFavouriteList(newFavouriteList));

      board.favourite = favourite;
      props.onUpdate(board);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTitle = async (e) => {
    clearTimeout(timer);
    const newTitle = e.target.value;
    setTitle(newTitle);

    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], title: newTitle };

    dispatch(setBoards(temp));

    board.title = newTitle;
    props.onUpdate(board);

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { title: newTitle });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateBoardType = async (e) => {
    clearTimeout(timer);
    const newBoardType = e.target.value;

    const boardTypesIndex = boardTypes.findIndex((e) => e.id === newBoardType);
    const currentBoardType = boardTypes[boardTypesIndex];
    let temp = [...boards];
    const index = temp.findIndex((e) => e.id === boardId);
    temp[index] = { ...temp[index], boardType: currentBoardType };
    dispatch(setBoards(temp));

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { boardType: newBoardType });
        setBoardType(newBoardType);
        const boardTypeIndex = boardTypes.findIndex(
          (e) => e.id === newBoardType
        );
        board.boardType = boardTypes[boardTypeIndex];
        props.onUpdate(board);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateBoardStartDate = async (e) => {
    clearTimeout(timer);
    const newStartDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { startDate: newStartDate });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    setStartDate(dayjs(newStartDate));

    board.startDate = newStartDate;
    props.onUpdate(board);
  };

  const updateBoardDueDate = async (e) => {
    clearTimeout(timer);
    const newDueDate = new Date(e);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, {
          dueDate: newDueDate,
        });
        setDueDate(dayjs(newDueDate));
        board.dueDate = newDueDate;
        props.onUpdate(board);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const updateDescription = async (e) => {
    clearTimeout(timer);
    const newDescription = e.target.value;
    setDescription(newDescription);

    board.description = newDescription;
    props.onUpdate(board);

    timer = setTimeout(async () => {
      try {
        await boardApi.update(boardId, { description: newDescription });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "image");
    } else {
      compressNonHeic(newName, "image");
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const deleteImage = async (e) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(board.id, { background: "" });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setBackground("");
    board.background = "";
    props.onUpdate(board);
    setFileURL(null);
  };

  const updateImage = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await boardApi.update(board.id, { background: image });
      } catch (err) {
        console.log(err);
      }
    }, timeout);

    setBackground(image);
    board.background = image;
    props.onUpdate(board);

    setFileURL(null);
  };

  const convertAndCompressHeic = async (file, type) => {
    const blob = file;
    try {
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.9,
      });
      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });

      setFileURL(convertedFile);
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file, type) => {
    try {
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        useWebWorker: true,
      });
      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });

      setFileURL(convertedFile);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        open={board !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={board !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Edit Board
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(board);
                  props.onClose();
                }}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <Grid item xs={12} sm={12}>
                Title
                <TextField
                  value={title}
                  onChange={updateTitle}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                Status Board
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  defaultValue={isFavourite}
                >
                  {[
                    { id: false, name: "Inactive" },
                    { id: true, name: "Active" },
                  ].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      name="gender"
                      value={value.id}
                      control={<Radio />}
                      label={value.name}
                      onChange={(e) => {
                        updateIsFavourite(e);
                      }}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={12}>
                Start Date
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={startDate}
                    onChange={updateBoardStartDate}
                    dateFormat="dd/MM/yyyy"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12}>
                Due Date
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dueDate}
                    onChange={updateBoardDueDate}
                    dateFormat="dd/MM/yyyy"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12}>
                Description
                <TextField
                  multiline
                  rows={2}
                  maxRows={4}
                  value={description}
                  onChange={updateDescription}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      // padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                Division
                <FormControl
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiFormControl-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                >
                  <Select
                    labelId="label-position"
                    name="boardType"
                    fullWidth
                    value={boardType}
                    id="boardType"
                    onChange={updateBoardType}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiFormControl-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                      "& .MuiInputBase-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  >
                    {boardTypes.map((boardType, index) => (
                      <MenuItem key={index} value={boardType.id}>
                        {boardType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                Edit Background
                <Box sx={{ textAlign: "center" }}>
                  {background !== "" && <img src={background} height="100" />}
                  {background !== "" && (
                    <Button
                      disableElevation={true}
                      variant="contained"
                      color="error"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 1,
                      }}
                      onClick={(e) => {
                        deleteImage(e);
                      }}
                      component="label"
                    >
                      <DeleteIcon sx={{ mr: 1, fontSize: "22px" }} /> Delete
                      Background
                    </Button>
                  )}

                  <Button
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      border:
                        theme.palette.mode === "dark"
                          ? "1px solid " + assets.colors.borderDark
                          : "1px solid " + assets.colors.borderLight,
                      color: "#999",
                      display: "flex",
                      alignItems: "center",
                      mt: 1,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                    component="label"
                  >
                    <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                    Background
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={uploadImage}
                      hidden
                    />
                  </Button>
                </Box>
                <Box sx={{ paddingTop: "10px" }}>
                  {fileURL && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                      onClick={updateImage}
                    >
                      Update Background
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default BoardBackgroundModal;
