import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import userApi from "../../api/userApi";
import authApi from "../../api/authApi";
import UserModal from "../../components/common/UserModal";
import UserLogModal from "../../components/common/UserLogModal";
import positionApi from "../../api/positionApi";
import roleApi from "../../api/roleApi";
import employeeTypeApi from "../../api/employeeTypeApi";
import workStatusApi from "../../api/workStatusApi";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Badge,
  Avatar,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "position",
    numeric: false,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "birth",
    numeric: false,
    disablePadding: false,
    label: "BirthDate",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Avatar",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showDialogDeleteUser,
    selected,
    requestSearch,
    searchFilter,
  } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{
            flex: "1 1 100%",
            display: "flex",
            alignItems: "center",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <FormatListBulletedIcon sx={{ mr: 1 }} /> List User
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => showDialogDeleteUser(selected)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <TextField
            sx={{
              background:
                theme.palette.mode === "dark"
                  ? assets.colors.inputTypeDark
                  : assets.colors.inputTypeLight,
              mt: 2,
              mr: 2,
              width: "300px",
            }}
            value={searchFilter}
            size="small"
            margin="normal"
            variant="outlined"
            placeholder="Search username..."
            type="search"
            onInput={(e) => requestSearch(e.target.value)}
          />
        </>
        // <Tooltip title="Filter list">
        //   <IconButton>
        //     <FilterListIcon />
        //   </IconButton>
        // </Tooltip>
        // <Button
        //   // onClick={logout}
        //   variant="outlined"
        //   size="small"
        //   color="success"
        //   sx={{
        //     width: "150px",
        //     alignItems: "center",
        //   }}
        // >
        //   <FilterListIcon fontSize="small" /> &nbsp;Add User
        // </Button>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Users = () => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [selectedUserLog, setSelectedUserLog] = useState(undefined);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [positions, setPositions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [workStatuses, setWorkStatus] = useState([]);
  const [employeeTypes, setEmployeeType] = useState([]);

  const [fileURL, setFileURL] = useState(null);
  const [fileURLKtp, setFileURLKtp] = useState(null);

  const [loading, setLoading] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  const [positionErrText, setPositionErrText] = useState("");
  const [usernameErrText, setUsernameErrText] = useState("");
  const [birthErrText, setBirthErrText] = useState("");
  const [roleErrText, setRoleErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");
  const [imageErrText, setImageErrText] = useState("");
  const [genderErrText, setGenderErrText] = useState("");
  const [employeeTypeErrText, setEmployeeTypeErrText] = useState("");
  const [emailErrText, setEmailErrText] = useState("");
  const [workStatusErrText, setWorkStatusErrText] = useState("");

  const [phoneNumberErrText, setPhoneNumberErrText] = useState("");
  const [ktpNumberErrText, setKtpNumberErrText] = useState("");
  const [joinDateErrText, setJoinDateErrText] = useState("");
  const [employeeIdErrText, setEmployeeIdErrText] = useState("");
  const [birthPlaceErrText, setBirthPlaceErrText] = useState("");
  const [addressErrText, setAddressErrText] = useState("");
  const [domicileErrText, setDomicileErrText] = useState("");
  const [religionErrText, setReligionErrText] = useState("");
  const [nationalityErrText, setNationalityErrText] = useState("");
  const [selfStatusErrText, setSelfStatusErrText] = useState("");
  const [childCountErrText, setChildCountErrText] = useState("");
  const [bloodErrText, setBloodErrText] = useState("");
  const [lastEducationErrText, setLastEducationErrText] = useState("");
  const [institutionNameErrText, setInstitutionNameErrText] = useState("");
  const [studyProgramErrText, setStudyProgramErrText] = useState("");
  const [placementErrText, setPlacementErrText] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [copyList, setCopyList] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const requestSearch = (searched) => {
    setCopyList(
      rows.filter((item) => item.username.toLowerCase().includes(searched))
    );
    setSearchFilter(searched);
  };

  useEffect(() => {
    const getPositions = async () => {
      try {
        const res = await positionApi.getAll();
        setPositions(res);
      } catch (err) {
        console.log(err);
      }
    };
    getPositions();
  }, []);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const res = await roleApi.getAll();
        setRoles(res);
      } catch (err) {
        console.log(err);
      }
    };
    getRoles();
    const getEmployeeType = async () => {
      try {
        const res = await employeeTypeApi.getAll();
        setEmployeeType(res);
      } catch (err) {
        console.log(err);
      }
    };
    getEmployeeType();
    const getWorkStatus = async () => {
      try {
        const res = await workStatusApi.getAll();
        setWorkStatus(res);
      } catch (err) {
        console.log(err);
      }
    };
    getWorkStatus();
  }, []);

  const onUpdateUser = (user) => {
    const newData = [...rows];
    const userIndex = newData.findIndex((e) => e._id === user.id);
    const positionIndex = positions.findIndex((e) => e.id === user.position);
    const roleIndex = roles.findIndex((e) => e.id === user.role);
    const employeeTypeIndex = employeeTypes.findIndex(
      (e) => e.id === user.employeeType
    );
    const workStatusIndex = workStatuses.findIndex(
      (e) => e.id === user.workStatus
    );
    let position;
    if (positionIndex === -1) {
      position = user.position.name;
    } else {
      position = positions[positionIndex].name;
    }
    let role;
    if (roleIndex === -1) {
      role = user.role.name;
    } else {
      role = roles[roleIndex].name;
    }
    let employeeType;
    if (employeeTypeIndex === -1) {
      employeeType =
        user.employeeType !== undefined ? user.employeeType.name : "";
    } else {
      employeeType = employeeTypes[employeeTypeIndex].name;
    }
    let workStatus;
    console.log(workStatusIndex);
    if (workStatusIndex === -1) {
      workStatus = user.workStatus !== undefined ? user.workStatus.name : "";
    } else {
      workStatus = workStatuses[workStatusIndex].name;
    }
    newData[userIndex].name = user.name;
    newData[userIndex].username = user.username;
    newData[userIndex].gender = user.gender;
    newData[userIndex].email = user.email;
    newData[userIndex].birth = dayjs(user.birth);
    // newData[userIndex].role = user.role;
    newData[userIndex].employeeType = employeeType;
    newData[userIndex].workStatus = workStatus;
    newData[userIndex].position = position;
    newData[userIndex].role = role;
    newData[userIndex].image = (
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        sx={{
          WebkitUserSelect: "none",
          MsUserSelect: "none",
          userSelect: "none",
          "& .MuiAvatar-root": {
            border:
              user.stAcc === "login"
                ? "3px solid green !important"
                : "3px solid #121212 !important",
          },
        }}
        badgeContent={<Typography>{user.mood.icon}</Typography>}
      >
        <Avatar alt={user.name} src={user.image} />
      </Badge>
    );
    setRows(newData);
    setCopyList(newData);
  };

  const deleteUsers = (users) => {
    users.forEach(async (value) => {
      try {
        await userApi.delete(value);
      } catch (err) {
        console.log(err);
      }
    });
    const newData = [...rows].filter((e) => !users.includes(e.id));
    setRows(newData);
    setCopyList(newData);
    setSelected([]);
    hideDialog();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const jsonData = [];
        const res = await userApi.getUsers();
        res.forEach((value, index) => {
          jsonData.push({
            _id: value.id,
            id: value.id,
            name: value.name,
            username: value.username,
            gender: value.gender,
            email: value.email,
            birth: dayjs(value.birth),
            role: value.role.name,
            position: value.position.name,
            employeeType:
              value.employeeType !== undefined ? value.employeeType.name : "",
            workStatus:
              value.workStatus !== undefined ? value.workStatus.name : "",
            image: (
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                sx={{
                  WebkitUserSelect: "none",
                  MsUserSelect: "none",
                  userSelect: "none",
                  "& .MuiAvatar-root": {
                    border:
                      value.stAcc === "login"
                        ? "3px solid green !important"
                        : "3px solid #121212 !important",
                  },
                }}
                badgeContent={<Typography>{value.mood.icon}</Typography>}
              >
                <Avatar alt={value.name} src={value.image} />
              </Badge>
            ),
            action: (
              <>
                <Tooltip title="Log Activity">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUserLog(value);
                    }}
                  >
                    <StickyNote2Icon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUser(value);
                    }}
                  >
                    <ModeEditOutlineOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </>
            ),
          });
        });
        setRows(jsonData);
        setCopyList(jsonData);
        // console.log(jsonData);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const visibleRowsCopy = useMemo(
    () =>
      stableSort(copyList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, copyList]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setNameErrText("");
    setPositionErrText("");
    setRoleErrText("");
    setUsernameErrText("");
    setBirthErrText("");
    setPasswordErrText("");
    setConfirmPasswordErrText("");
    setImageErrText("");
    setEmployeeTypeErrText("");
    setGenderErrText("");
    setEmailErrText("");
    setWorkStatusErrText("");

    const data = new FormData(e.target);
    const name = data.get("name").trim();
    const username = data.get("username").trim();
    const birth = data.get("birth");
    const role = data.get("role").trim();
    const email = data.get("email").trim();
    const employeeType = data.get("employeeType").trim();
    const gender = data.get("gender").trim();
    const workStatus = data.get("workStatus").trim();
    const position = data.get("position").trim();
    const password = data.get("password").trim();
    const confirmPassword = data.get("confirmPassword").trim();
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);

    let ktpImage = "";
    if (fileURLKtp !== null) {
      ktpImage = await getBase64(fileURLKtp)
        .then((res) => res)
        .catch((err) => err);
    }

    const mood = "6675145eb3287713ca577fdb";
    const phoneNumber = data.get("phoneNumber").trim();
    const ktpNumber = data.get("ktpNumber").trim();
    const joinDate = data.get("joinDate");
    const employeeId = data.get("employeeId").trim();
    const birthPlace = data.get("birthPlace").trim();
    const address = data.get("address").trim();
    const domicile = data.get("domicile").trim();
    const religion = data.get("religion").trim();
    const nationality = data.get("nationality").trim();
    const selfStatus = data.get("selfStatus").trim();
    const childCount = data.get("childCount").trim();
    const blood = data.get("blood").trim();
    const lastEducation = data.get("lastEducation").trim();
    const institutionName = data.get("institutionName").trim();
    const studyProgram = data.get("studyProgram").trim();
    const placement = data.get("placement").trim();

    let err = false;

    if (name === "") {
      err = true;
      setNameErrText("Please fill this field");
    }
    if (position === "") {
      err = true;
      setPositionErrText("Please fill this field");
    }
    if (role === "") {
      err = true;
      setRoleErrText("Please fill this field");
    }
    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (birth === "") {
      err = true;
      setBirthErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }
    if (confirmPassword === "") {
      err = true;
      setConfirmPasswordErrText("Please fill this field");
    }
    if (password !== confirmPassword) {
      err = true;
      setConfirmPasswordErrText("Confirm password not match");
    }
    if (image === "") {
      err = true;
      setImageErrText("Please fill this field");
    }
    if (workStatus === "") {
      err = true;
      setWorkStatusErrText("Please fill this field");
    }
    if (email === "") {
      err = true;
      setEmailErrText("Please fill this field");
    }
    if (gender === "") {
      err = true;
      setGenderErrText("Please fill this field");
    }
    if (employeeType === "") {
      err = true;
      setEmployeeTypeErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const birthDate = birth.split("/")[1];
      const birthMonth = birth.split("/")[0];
      const res = await authApi.signup({
        name,
        position,
        username,
        birth,
        password,
        confirmPassword,
        image,
        ktpImage,
        role,
        mood,
        birthDate,
        birthMonth,
        email,
        workStatus,
        employeeType,
        gender,
        phoneNumber,
        ktpNumber,
        joinDate,
        employeeId,
        birthPlace,
        address,
        domicile,
        religion,
        nationality,
        selfStatus,
        childCount,
        blood,
        lastEducation,
        institutionName,
        studyProgram,
        placement,
      });
      setLoading(false);
      const positionIndex = positions.findIndex(
        (e) => e.id === res.user.position
      );
      const roleIndex = roles.findIndex((e) => e.id === res.user.role);
      const employeeTypeIndex = employeeTypes.findIndex(
        (e) => e.id === res.user.employeeType
      );
      const workStatusIndex = workStatuses.findIndex(
        (e) => e.id === res.user.workStatus
      );
      const newPosition = positions[positionIndex].name;
      const newRole = roles[roleIndex].name;
      const newEmployeeType = employeeTypes[employeeTypeIndex].name;
      const newWorkStatus = workStatuses[workStatusIndex].name;
      res.user.position = positions[positionIndex];
      res.user.role = roles[roleIndex];
      res.user.employeeType = employeeTypes[employeeTypeIndex];
      res.user.workStatus = workStatuses[workStatusIndex];
      const newJsonData = {
        _id: res.user._id,
        id: res.user.id,
        name: res.user.name,
        username: res.user.username,
        email: res.user.email,
        gender: res.user.gender,
        birth: dayjs(res.user.birth),
        role: newRole,
        employeeType: newEmployeeType,
        workStatus: newWorkStatus,
        position: newPosition,
        image: (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={<Typography>{res.user.mood.icon}</Typography>}
          >
            <Avatar alt={res.user.name} src={res.user.image} />
          </Badge>
        ),
        action: (
          <>
            <Tooltip title="Log Activity">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedUserLog(res.user);
                }}
              >
                <StickyNote2Icon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedUser(res.user);
                }}
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      };
      console.log(newJsonData);
      const newData = [...rows];
      newData.unshift(newJsonData);
      console.log(newData);
      setRows(newData);
      setCopyList(newData);
      e.target.reset();
      setFileURL(null);
      setFileURLKtp(null);
    } catch (err) {
      console.log(err);
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "name") {
          setNameErrText(e.msg);
        }
        if (e.param === "position") {
          setPositionErrText(e.msg);
        }
        if (e.param === "username") {
          setUsernameErrText(e.msg);
        }
        if (e.param === "birth") {
          setBirthErrText(e.msg);
        }
        if (e.param === "role") {
          setRoleErrText(e.msg);
        }
        if (e.param === "password") {
          setPasswordErrText(e.msg);
        }
        if (e.param === "confirmPassword") {
          setConfirmPasswordErrText(e.msg);
        }
        if (e.param === "image") {
          setImageErrText(e.msg);
        }
        if (e.param === "gender") {
          setGenderErrText(e.msg);
        }
        if (e.param === "employeeType") {
          setEmployeeTypeErrText(e.msg);
        }
        if (e.param === "email") {
          setEmailErrText(e.msg);
        }
        if (e.param === "workStatus") {
          setWorkStatusErrText(e.msg);
        }
      });
      setLoading(false);
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const [openDialogDeleteUser, setOpenDialogDeleteUser] = useState(false);
  const [idDeleteUser, setIdDeleteUser] = useState(false);

  const showDialogDeleteUser = (sectionId) => {
    setOpenDialogDeleteUser(true);
    setIdDeleteUser(sectionId);
  };

  const hideDialog = () => {
    setOpenDialogDeleteUser(false);
  };

  const convertAndCompressHeic = async (file, type) => {
    const blob = file;
    try {
      setLoading(true);
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
        setFileURLKtp(convertedFile);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file, type) => {
    try {
      setLoading(true);
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 100,
        useWebWorker: true,
      });
      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      } else {
        setFileURLKtp(convertedFile);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "image");
    } else {
      compressNonHeic(newName, "image");
    }
  };

  const uploadKtp = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "ktp");
    } else {
      compressNonHeic(newName, "ktp");
    }
  };

  return (
    <>
      {openDialogDeleteUser && (
        <Dialog
          open={openDialogDeleteUser}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete user
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteUsers(idDeleteUser)} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          👥 Manage User
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.rolesTextDark
                : assets.colors.rolesTextLight,
          }}
        >
          <i>Manage all users in the zabran scrum board application</i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                m: 2,
                p: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  mb: 2,
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.rolesTextDark
                      : assets.colors.rolesTextLight,
                }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <PersonAddIcon sx={{ mr: 1 }} /> Add User
              </Typography>
              <Box
                component="form"
                sx={{ mt: 1 }}
                onSubmit={handleSubmit}
                noValidate
              >
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Name <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    size="small"
                    disabled={loading}
                    error={nameErrText !== ""}
                    helperText={nameErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Username <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    disabled={loading}
                    error={usernameErrText !== ""}
                    helperText={usernameErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Email <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    disabled={loading}
                    error={emailErrText !== ""}
                    helperText={emailErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Password <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    name="password"
                    // type="password"
                    type={showPassword ? "text" : "password"}
                    disabled={loading}
                    error={passwordErrText !== ""}
                    helperText={passwordErrText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Confirm Password <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    size="small"
                    margin="normal"
                    required
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    disabled={loading}
                    error={confirmPasswordErrText !== ""}
                    helperText={confirmPasswordErrText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Gender <span style={{ color: "red" }}>*</span>
                </Typography>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  error={genderErrText !== ""}
                  helperText={genderErrText}
                >
                  {["male", "female"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      name="gender"
                      value={value}
                      control={<Radio />}
                      label={value}
                      sx={{
                        color:
                          value === "male"
                            ? blue[800]
                            : value === "female"
                            ? pink[800]
                            : "",
                        "&.Mui-checked": {
                          color:
                            value === "male"
                              ? blue[800]
                              : value === "female"
                              ? pink[800]
                              : "",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Select Role <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={roleErrText !== ""}
                    helperText={roleErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="role"
                      error={roleErrText !== ""}
                    >
                      {roles.map((role, index) => (
                        <MenuItem key={index} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Select Work Type <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={employeeTypeErrText !== ""}
                    helperText={employeeTypeErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="employeeType"
                      error={employeeTypeErrText !== ""}
                    >
                      {employeeTypes.map((employeeType, index) => (
                        <MenuItem key={index} value={employeeType.id}>
                          {employeeType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Select Work Status <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={workStatusErrText !== ""}
                    helperText={workStatusErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      defaultValue="6683b29a96f41dc6eebad4b8"
                      disabled={loading}
                      name="workStatus"
                      error={workStatusErrText !== ""}
                    >
                      {workStatuses.map((workStatus, index) => (
                        <MenuItem key={index} value={workStatus.id}>
                          {workStatus.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Position <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={positionErrText !== ""}
                    helperText={positionErrText}
                  >
                    <Select
                      labelId="label-position"
                      name="position"
                      required
                      size="small"
                      fullWidth
                      id="position"
                      disabled={loading}
                      error={positionErrText !== ""}
                    >
                      {positions.map((position, index) => (
                        <MenuItem key={index} value={position.id}>
                          {position.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Birth Date <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      name="birth"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                        mt: 0,
                        mb: 1,
                        width: "100%",
                      }}
                      error={birthErrText !== ""}
                      helperText={birthErrText}
                    />
                  </LocalizationProvider>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Avatar (max 1mb) <span style={{ color: "red" }}>*</span>
                </Typography>
                <Button
                  disableElevation={true}
                  variant="contained"
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid " + assets.colors.borderDark
                        : "1px solid " + assets.colors.borderLight,
                    color: "#999",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    mb: 1,
                  }}
                  component="label"
                >
                  <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                  Image
                  <input
                    type="file"
                    name="image"
                    disabled={loading}
                    error={imageErrText !== ""}
                    helperText={imageErrText}
                    accept="image/*"
                    onChange={uploadImage}
                    required
                    hidden
                  />
                </Button>
                {fileURL && (
                  <Typography sx={{ fontSize: "14px", mb: 1 }}>
                    Upload Image Successfully
                  </Typography>
                )}
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  KTP (max 1mb)
                </Typography>
                <Button
                  disableElevation={true}
                  variant="contained"
                  sx={{
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                    border:
                      theme.palette.mode === "dark"
                        ? "1px solid " + assets.colors.borderDark
                        : "1px solid " + assets.colors.borderLight,
                    color: "#999",
                    display: "flex",
                    alignItems: "center",
                    "&:hover": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    mb: 1,
                  }}
                  component="label"
                >
                  <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                  KTP
                  <input
                    type="file"
                    name="image"
                    disabled={loading}
                    accept="image/*"
                    onChange={uploadKtp}
                    required
                    hidden
                  />
                </Button>
                {fileURLKtp && (
                  <Typography sx={{ fontSize: "14px", mb: 1 }}>
                    Upload KTP Successfully
                  </Typography>
                )}
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    size="small"
                    disabled={loading}
                    error={phoneNumberErrText !== ""}
                    helperText={phoneNumberErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  KTP Number
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="ktpNumber"
                    name="ktpNumber"
                    size="small"
                    disabled={loading}
                    error={ktpNumberErrText !== ""}
                    helperText={ktpNumberErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Last Join Zabran
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      name="joinDate"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: "8.5px 0px 8.5px 8.5px",
                        },
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                        mt: 0,
                        mb: 1,
                        width: "100%",
                      }}
                      error={joinDateErrText !== ""}
                      helperText={joinDateErrText}
                    />
                  </LocalizationProvider>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Employee ID
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="employeeId"
                    name="employeeId"
                    size="small"
                    disabled={loading}
                    error={employeeIdErrText !== ""}
                    helperText={employeeIdErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Birth Place
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="birthPlace"
                    name="birthPlace"
                    size="small"
                    disabled={loading}
                    error={birthPlaceErrText !== ""}
                    helperText={birthPlaceErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Full Address
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    multiline
                    rows={2}
                    maxRows={4}
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    name="address"
                    size="small"
                    disabled={loading}
                    error={addressErrText !== ""}
                    helperText={addressErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Domicile
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="domicile"
                    name="domicile"
                    size="small"
                    disabled={loading}
                    error={domicileErrText !== ""}
                    helperText={domicileErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Religion
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={religionErrText !== ""}
                    helperText={religionErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="religion"
                      error={religionErrText !== ""}
                    >
                      <MenuItem value="islam">Islam</MenuItem>
                      <MenuItem value="protestan">Kristen Protestan</MenuItem>
                      <MenuItem value="katolik">Kristen Katolik</MenuItem>
                      <MenuItem value="hindu">Hindu</MenuItem>
                      <MenuItem value="buddha">Buddha</MenuItem>
                      <MenuItem value="konghucu">Konghucu</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Nationality
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    defaultValue="Indonesia"
                    margin="normal"
                    required
                    fullWidth
                    id="nationality"
                    name="nationality"
                    size="small"
                    disabled={loading}
                    error={nationalityErrText !== ""}
                    helperText={nationalityErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Self Status
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={selfStatusErrText !== ""}
                    helperText={selfStatusErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="selfStatus"
                      error={selfStatusErrText !== ""}
                    >
                      <MenuItem value="married">Menikah</MenuItem>
                      <MenuItem value="single">Single</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Number of Children
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="childCount"
                    name="childCount"
                    size="small"
                    disabled={loading}
                    error={childCountErrText !== ""}
                    helperText={childCountErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Blood
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={bloodErrText !== ""}
                    helperText={bloodErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="blood"
                      error={bloodErrText !== ""}
                    >
                      <MenuItem value="a">A</MenuItem>
                      <MenuItem value="b">AB</MenuItem>
                      <MenuItem value="ab">B</MenuItem>
                      <MenuItem value="o">O</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Last Education
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                      mb: 1,
                    }}
                    error={lastEducationErrText !== ""}
                    helperText={lastEducationErrText}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      disabled={loading}
                      name="lastEducation"
                      error={lastEducationErrText !== ""}
                    >
                      <MenuItem value="sd">SD</MenuItem>
                      <MenuItem value="smp">SMP</MenuItem>
                      <MenuItem value="sma">SMA/SMK</MenuItem>
                      <MenuItem value="d3">D3</MenuItem>
                      <MenuItem value="s1">D4/S1</MenuItem>
                      <MenuItem value="s2">S2</MenuItem>
                      <MenuItem value="s3">S3</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Educational Institution Name{" "}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="institutionName"
                    name="institutionName"
                    size="small"
                    disabled={loading}
                    error={institutionNameErrText !== ""}
                    helperText={institutionNameErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Study Program
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="studyProgram"
                    name="studyProgram"
                    size="small"
                    disabled={loading}
                    error={studyProgramErrText !== ""}
                    helperText={studyProgramErrText}
                  />
                </Box>
                <Typography sx={{ color: "rgb(108, 108, 108)" }}>
                  Placement
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    gap: "1px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      mt: 0,
                    }}
                    margin="normal"
                    required
                    fullWidth
                    id="placement"
                    name="placement"
                    size="small"
                    disabled={loading}
                    error={placementErrText !== ""}
                    helperText={placementErrText}
                  />
                </Box>
                <LoadingButton
                  sx={{ mt: 3, mb: 2 }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  loading={loading}
                >
                  <PersonAddIcon sx={{ mr: 1 }} /> Create User
                </LoadingButton>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper
              sx={{
                m: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <EnhancedTableToolbar
                numSelected={selected.length}
                showDialogDeleteUser={showDialogDeleteUser}
                requestSearch={requestSearch}
                searchFilter={searchFilter}
                selected={selected}
              />
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {(visibleRowsCopy.length > 0
                      ? visibleRowsCopy
                      : visibleRows
                    ).map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.name}
                          </TableCell>
                          <TableCell align="left">{row.username}</TableCell>
                          <TableCell align="left">{row.role}</TableCell>
                          <TableCell align="left">{row.position}</TableCell>
                          <TableCell align="left">
                            {dayjs(row.birth).format("DD MMM YYYY")}
                          </TableCell>
                          <TableCell align="left">{row.image}</TableCell>
                          <TableCell align="left">{row.action}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <UserModal
        user={selectedUser}
        onClose={() => setSelectedUser(undefined)}
        onUpdate={onUpdateUser}
        positions={positions}
        roles={roles}
        employeeTypes={employeeTypes}
        workStatuses={workStatuses}
      />
      <UserLogModal
        user={selectedUserLog}
        onClose={() => setSelectedUserLog(undefined)}
      />
    </>
  );
};

export default Users;
