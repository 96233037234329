import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import noteApi from "../../api/noteApi";
import EmojiPicker from "./EmojiPicker";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MyCustomUploadAdapterPlugin from "./ckImageUploader";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "100%",
    color: "#999",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "100%",
    color: "#999",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const NoteModal = (props) => {
  const [note, setNote] = useState(props.note);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("📃");
  const [priority, setPriority] = useState("");

  useEffect(() => {
    setNote(props.note);
    setName(props.note !== undefined ? props.note.name : "");
    setIcon(props.note !== undefined ? props.note.icon : "");
    setDescription(props.note !== undefined ? props.note.description : "");
    setPriority(props.note !== undefined ? props.note.priority : "");
    if (props.note !== undefined) {
      isModalClosed = false;
    }
  }, [props.note]);

  const onIconChange = async (newIcon) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await noteApi.update(note.id, { icon: newIcon });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    note.icon = newIcon;
    setIcon(newIcon);
    props.onUpdate(note);
  };

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(note);
    props.onClose();
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await noteApi.update(note.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    note.name = newName;
    setName(newName);
    props.onUpdate(note);
  };

  const updateDescription = async (e, editor) => {
    clearTimeout(timer);
    const newDescription = editor.getData();

    if (!isModalClosed) {
      timer = setTimeout(async () => {
        try {
          await noteApi.update(note.id, { description: newDescription });
        } catch (err) {
          console.log(err);
        }
      }, timeout);
      note.description = newDescription;
      setDescription(newDescription);
      props.onUpdate(note);
    }
  };

  const updatePriority = async (e) => {
    clearTimeout(timer);
    const newPriority = e.target.value;
    timer = setTimeout(async () => {
      try {
        await noteApi.update(note.id, { priority: newPriority });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    note.priority = newPriority;
    setPriority(newPriority);
    props.onUpdate(note);
  };

  return (
    <>
      <Modal
        open={note !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={note !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(note);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                padding: "0rem 2rem",
                width: "100%",
              }}
            >
              <Box>
                Icon
                <EmojiPicker icon={icon} onChange={onIconChange} />
              </Box>
              <Box sx={{ mt: 1 }}>
                Color
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={priority}
                >
                  {["low", "medium", "high"].map((value, index) => (
                    <FormControlLabel
                      key={index}
                      value={value}
                      onChange={(e) => {
                        updatePriority(e);
                      }}
                      control={
                        <Radio
                          sx={{
                            color:
                              value === "low"
                                ? yellow[800]
                                : value === "medium"
                                ? orange[800]
                                : red[800],
                            "&.Mui-checked": {
                              color:
                                value === "low"
                                  ? yellow[800]
                                  : value === "medium"
                                  ? orange[800]
                                  : red[800],
                            },
                          }}
                        />
                      }
                      label={value}
                    />
                  ))}
                </RadioGroup>
              </Box>
              <Box>
                Name
                <TextField
                  value={name}
                  onChange={updateName}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
              </Box>
              <Box>
                Description
                <br />
                <CKEditor
                  data={description}
                  editor={ClassicEditor}
                  config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                  id="description"
                  onChange={updateDescription}
                />
                {/* <TextField
                  multiline
                  rows={2}
                  maxRows={4}
                  value={description}
                  onChange={updateDescription}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                      padding: 0,
                    },
                    marginBottom: "10px",
                  }}
                /> */}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NoteModal;
