import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import boardTypeApi from "../../api/boardTypeApi";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "20%",
    color: "#999",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "30%",
    color: "#999",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const BoardTypeModal = (props) => {
  const [boardType, setBoardType] = useState(props.boardType);
  const [name, setName] = useState("");

  useEffect(() => {
    setBoardType(props.boardType);
    setName(props.boardType !== undefined ? props.boardType.name : "");
    if (props.boardType !== undefined) {
      isModalClosed = false;
    }
  }, [props.boardType]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(boardType);
    props.onClose();
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await boardTypeApi.update(boardType.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    boardType.name = newName;
    setName(newName);
    props.onUpdate(boardType);
  };

  return (
    <>
      <Modal
        open={boardType !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={boardType !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(boardType);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0rem 2rem",
              }}
            >
              <Box>
                Name
                <TextField
                  value={name}
                  onChange={updateName}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default BoardTypeModal;
