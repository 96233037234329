import { useMemo, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import noteApi from "../../api/noteApi";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import EmojiPicker from "../../components/common/EmojiPicker";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import { Badge, Grid } from "@mui/material";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";

const CompanyRoadmapDetail = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user.value);
  const [notes, setNotes] = useState([]);
  const { noteId } = useParams();
  const [note, setNote] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const dense = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [icon, setIcon] = useState("📃");

  const [fileURL, setFileURL] = useState(null);

  const [loading, setLoading] = useState(false);
  const [nameErrText, setNameErrText] = useState("");
  const [descriptionErrText, setDescriptionErrText] = useState("");

  useEffect(() => {
    const getNotes = async () => {
      try {
        const res = await noteApi.getAll();
        setNotes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getNotes();
    if (noteId !== "all") {
      const getBoard = async () => {
        try {
          const userId = user.id;
          const res = await noteApi.getOne(noteId, userId);
          setNote(res);
        } catch (err) {
          console.log(err);
        }
      };
      getBoard();
    }
  }, [noteId]);

  return (
    <>
      <Box
        sx={{
          // width: "100%",
          margin: "5px 25px 0px 25px",
        }}
      >
        <Typography
          sx={{
            padding: 0,
            fontSize: "1.5rem",
            fontWeight: "500",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.notesTextDark
                : assets.colors.notesTextLight,
          }}
        >
          🛣️ Roadmap
        </Typography>
        <Typography
          sx={{
            padding: 0,
            marginTop: "2px",
            fontSize: "14px",
            fontWeight: "400",
            color:
              theme.palette.mode === "dark"
                ? assets.colors.notesTextDark
                : assets.colors.notesTextLight,
          }}
        >
          <i>List Roadmap in the zabran scrum board application</i>
        </Typography>
      </Box>
      <Box
        sx={{
          // width: "100%",
          margin: "0px 10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Paper
              sx={{
                // width: "50%",
                m: 2,
                p: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.notesTextDark
                      : assets.colors.notesTextLight,
                }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                <FormatListBulletedIcon sx={{ mr: 1 }} /> List Roadmap
              </Typography>
              <Box sx={{ width: "100%", mt: 2 }}>
                <nav aria-label="main mailbox folders">
                  {notes.map((note, index) => (
                    <List key={{ index }} sx={{ mb: 0, pb: 0, mt: 0, pt: 0 }}>
                      <Divider />
                      <ListItem disablePadding>
                        <ListItemButton
                          component={Link}
                          to={`/dashboard/company_roadmaps/${note.id}`}
                          sx={{ pl: 0, pr: 0, ml: 0, mr: 0 }}
                        >
                          <ListItemIcon sx={{ minWidth: "35px" }}>
                            {note.icon}
                          </ListItemIcon>
                          <ListItemText primary={note.name} />
                          {note.isRead.indexOf(user.id) === -1 ? (
                            <Badge color="error" variant="dot">
                              <ListItemText
                                sx={{
                                  whiteSpace: "nowrap",
                                  ml: 1,
                                  "& .MuiTypography-root": {
                                    float: "right",
                                  },
                                }}
                                secondary={dayjs(note.createdAt).format(
                                  "DD MMM YYYY"
                                )}
                              />
                            </Badge>
                          ) : (
                            <ListItemText
                              sx={{
                                ml: 1,
                                whiteSpace: "nowrap",
                                "& .MuiTypography-root": {
                                  float: "right",
                                },
                              }}
                              secondary={dayjs(note.createdAt).format(
                                "DD MMM YYYY"
                              )}
                            />
                          )}
                        </ListItemButton>
                      </ListItem>
                    </List>
                  ))}
                </nav>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Paper
              sx={{
                // width: "100%",
                m: 2,
                p: 2,
                border:
                  theme.palette.mode === "dark"
                    ? "1px solid " + assets.colors.borderDark
                    : "1px solid " + assets.colors.borderLight,
              }}
            >
              <Typography
                sx={{
                  flex: "1 1 100%",
                  display: "flex",
                  alignItems: "center",
                  color:
                    theme.palette.mode === "dark"
                      ? assets.colors.notesTextDark
                      : assets.colors.notesTextLight,
                }}
                variant="h6"
                id="tableTitle"
                component="div"
              ></Typography>
              {noteId !== "all" ? (
                <>
                  <Typography
                    sx={{
                      mt: 2,
                      mb: 1,
                      padding: 0,
                      fontSize: "1.5rem",
                      fontWeight: "500",
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.notesTextDark
                          : assets.colors.notesTextLight,
                    }}
                  >
                    {note.icon} {note.name}
                  </Typography>
                  <Typography
                    sx={{
                      padding: 0,
                      mb: 2,
                      marginTop: "2px",
                      fontSize: "14px",
                      fontWeight: "300",
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.notesTextDark
                          : assets.colors.notesTextLight,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          borderRadius: "50%",
                          width: "17px",
                          height: "17px",
                          marginRight: "5px",
                          backgroundColor:
                            note.priority === "blue"
                              ? blue[900]
                              : note.priority === "orange"
                              ? orange[900]
                              : note.priority === "green"
                              ? green[900]
                              : note.priority === "yellow"
                              ? yellow[900]
                              : red[900],
                        }}
                      ></Box>
                      {dayjs(note.createdAt).format("DD MMM YYYY")} -{" "}
                      {note.user && note.user.name}
                    </Box>
                  </Typography>
                  <Typography
                    sx={{
                      padding: 0,
                      marginTop: "2px",
                      fontSize: "14px",
                      fontWeight: "400",
                      color:
                        theme.palette.mode === "dark"
                          ? assets.colors.notesTextDark
                          : assets.colors.notesTextLight,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: note.description,
                      }}
                      style={{
                        MsWordBreak: "break-all",
                        wordBreak: "break-all",
                        wordBreak: "break-word",
                        WebkitHyphens: "auto",
                        MozHyphens: "auto",
                        MsHyphens: "auto",
                        hyphens: "auto"
                      }}
                      className="editor ck-content"
                    ></div>
                    {/* {note.description} */}
                  </Typography>
                </>
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CompanyRoadmapDetail;
