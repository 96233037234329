import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  Typography,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import React, { useEffect, useState } from "react";
import { Cancel } from "@mui/icons-material";
import iconApi from "../../api/iconApi";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import assets from "../../assets/index";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import dayjs from "dayjs";
import Moment from "moment";
import { pink, blue } from "@mui/material/colors";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "60%",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    color: "#999",
    height: "60%",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const AssessmentModal = (props) => {
  const theme = useTheme();
  const [fileURL, setFileURL] = useState(null);

  const [assessment, setAssessment] = useState(props.assessment);
  const [name, setName] = useState("");
  const [scale, setScale] = useState("");

  useEffect(() => {
    setFileURL(null);
    setAssessment(props.assessment);
    setName(props.assessment !== undefined ? props.assessment.name : "");
    setScale(props.assessment !== undefined ? props.assessment.scale : "");
    if (props.assessment !== undefined) {
      isModalClosed = false;
    }
  }, [props.assessment]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(assessment);
    props.onClose();
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await iconApi.update(assessment.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    console.log(assessment);

    assessment.name = newName;
    props.onUpdate(assessment);
    setName(newName);
  };

  const updateScale = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await iconApi.update(assessment.id, { scale: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    assessment.scale = newName;
    props.onUpdate(assessment);
    setScale(newName);
  };

  const uploadImage = async (e) => {
    const newName = e.target.files[0];
    if (newName.type === "image/heic") {
      convertAndCompressHeic(newName, "image");
    } else {
      compressNonHeic(newName, "image");
    }
  };

  async function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const updateImage = async (e) => {
    clearTimeout(timer);
    const image = await getBase64(fileURL)
      .then((res) => res)
      .catch((err) => err);
    timer = setTimeout(async () => {
      try {
        await iconApi.update(assessment.id, { icon_url: image });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    assessment.icon_url = image;
    props.onUpdate(assessment);
    setFileURL(null);
  };

  const convertAndCompressHeic = async (file, type) => {
    const blob = file;
    try {
      const convertedBlob = await heic2any({
        blob,
        toType: "image/jpeg",
        quality: 0.5,
      });
      const convertedFile = new File([convertedBlob], file.name, {
        type: convertedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const compressNonHeic = async (file, type) => {
    try {
      const compressedBlob = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 75,
        useWebWorker: true,
      });
      const convertedFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type,
      });
      if (type === "image") {
        setFileURL(convertedFile);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Modal
        open={assessment !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={assessment !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                mb: 1,
                pl: 2,
                pr: 2,
                pt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                Edit Assessment
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(assessment);
                  props.onClose();
                }}
              />
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", alignItems: "center", p: 2 }}
            >
              <Grid item xs={12} sm={12}>
                <Box>
                  Name
                  <TextField
                    value={name}
                    onChange={updateName}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Box>
                  Scale
                  <TextField
                    type="number"
                    value={scale}
                    onChange={updateScale}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8.5px 0px 8.5px 8.5px",
                      },
                      "& .MuiOutlinedInput-root": {
                        background:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box>
                  Icon
                  <Button
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                      border:
                        theme.palette.mode === "dark"
                          ? "1px solid " + assets.colors.borderDark
                          : "1px solid " + assets.colors.borderLight,
                      color: "#999",
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? assets.colors.inputTypeDark
                            : assets.colors.inputTypeLight,
                      },
                    }}
                    component="label"
                  >
                    <CloudUploadIcon sx={{ mr: 1, fontSize: "22px" }} /> Upload
                    Icon
                    <input
                      type="file"
                      name="image"
                      accept="image/*"
                      onChange={uploadImage}
                      hidden
                    />
                  </Button>
                </Box>
                <Box sx={{ paddingTop: "5px" }}>
                  {fileURL && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ width: "100%" }}
                      onClick={updateImage}
                    >
                      Update Icon
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AssessmentModal;
