import {
  Backdrop,
  Fade,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import boardApi from "../../api/boardApi";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";

let modalStyle = "";
if(window.innerWidth > 768){
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    color: "#999",
    pt: 1,
    pr: 3,
    pb: 1,
    pl: 3,
    height: "50%",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    color: "#999",
    pt: 1,
    pr: 3,
    pb: 1,
    pl: 3,
    height: "90%",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const UserBoardModal = (props) => {
  const user = useSelector((state) => state.user.value);
  const boardId = props.boardId;
  let users = props.users;
  const allUsers = props.allUsers;
  const [userId, setUserId] = useState(null);
  const [userIdDelete, setUserIdDelete] = useState("");

  const currentAllUsers = [];
  allUsers.forEach((user, index) => {
    const colIndex = users.findIndex((e) => e.id === user.id);
    if (colIndex === -1) {
      currentAllUsers.push(user);
    }
  });

  const [openDialogCreateUserBoard, setOpenDialogCreateUserBoard] =
    useState(false);
  const [openDialogDeleteUserBoard, setOpenDialogDeleteUserBoard] =
    useState(false);

  const showDialogDeleteUserBoard = (userId) => {
    setOpenDialogDeleteUserBoard(true);
    setUserIdDelete(userId);
  };

  const showDialogCreateUserBoard = () => {
    setOpenDialogCreateUserBoard(true);
  };

  const hideDialog = () => {
    setOpenDialogDeleteUserBoard(false);
    setOpenDialogCreateUserBoard(false);
  };

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(users);
    props.onClose();
  };

  const createUserBoard = async () => {
    try {
      if (userId !== "") {
        await boardApi.deleteUser(boardId, userId);
        users = users.filter((e) => e.id !== userId);
        props.onUpdate(users);
        setUserId(null);

        const res = await boardApi.createUser(boardId, userId);
        users.push(res);
        props.onUpdate(users);
        setUserId(null);
      }
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteUserBoard = async (userId) => {
    try {
      if (userId !== "") {
        await boardApi.deleteUser(boardId, userId);
        users = users.filter((e) => e.id !== userId);
        props.onUpdate(users);
        setUserId(null);
      }
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const selectUser = (e, value) => {
    setUserId(value.id);
  };

  return (
    <>
      {openDialogCreateUserBoard && (
        <Dialog
          open={openDialogCreateUserBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create to this board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={createUserBoard} color="primary">
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDialogDeleteUserBoard && (
        <Dialog
          open={openDialogDeleteUserBoard}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete user from this board
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteUserBoard(userIdDelete)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        open={boardId !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={boardId !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="h6"
                component="div"
              >
                Add / Delete Users
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px 20px 0",
                justifyContent: "space-between",
              }}
            >
              <FormControl size="small" fullWidth sx={{ mr: 2 }}>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={currentAllUsers}
                  // sx={{ width: 300 }}
                  autoHighlight
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                      <Box
                        key={key}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...optionProps}
                      >
                        {option.name} ({option.position.name})
                      </Box>
                    );
                  }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" />
                  )}
                  defaultValue={userId}
                  onChange={selectUser}
                />
                {/* <InputLabel id="label-position">Select User</InputLabel> */}
                {/* <Select
                  value={userId}
                  sx={{ width: "100%", color: "blue" }}
                  onChange={selectUser}
                  label="Select User"
                >
                  {currentAllUsers.map((user, index) => (
                    <MenuItem key={`${index}-currentUser`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select> */}
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "150px" }}
                onClick={() => showDialogCreateUserBoard()}
              >
                Create
              </Button>
            </Box>
            {users.map((user, index) => (
              <Box
                key={`${index}-users`}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar alt={user.name} src={user.image} />
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="h6"
                    component="div"
                  >
                    {user.name}
                  </Typography>
                </Stack>
                <Button onClick={() => showDialogDeleteUserBoard(user.id)}>
                  Delete
                </Button>
              </Box>
            ))}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserBoardModal;
