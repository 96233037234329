import {
  Backdrop,
  Fade,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import taskApi from "../../api/taskApi";
import {
  pink,
  blue,
  red,
  purple,
  orange,
  yellow,
  green,
} from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

let modalStyle = "";
if(window.innerWidth > 768){
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    pt: 1,
    pr: 3,
    pb: 1,
    pl: 3,
    height: "50%",
    color: "#999",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    pt: 1,
    pr: 3,
    pb: 1,
    pl: 3,
    height: "90%",
    color: "#999",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const UserTaskModal = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.value);
  const boardId = props.boardId;
  const taskId = props.taskId;
  const [task, setTask] = useState(props.task);
  let users = props.users;
  const allUsers = props.allUsers;
  const [userId, setUserId] = useState("");
  const [userIdDelete, setUserIdDelete] = useState("");

  useEffect(() => {
    setTask(props.task);
  }, []);

  const currentAllUsers = [];
  allUsers.forEach((user, index) => {
    const colIndex = users.findIndex((e) => e.id === user.id);
    if (colIndex === -1) {
      currentAllUsers.push(user);
    }
  });

  const [openDialogCreateUserTask, setOpenDialogCreateUserTask] =
    useState(false);
  const [openDialogDeleteUserTask, setOpenDialogDeleteUserTask] =
    useState(false);

  const showDialogDeleteUserTask = (userId) => {
    setOpenDialogDeleteUserTask(true);
    setUserIdDelete(userId);
  };

  const showDialogCreateUserTask = () => {
    setOpenDialogCreateUserTask(true);
  };

  const hideDialog = () => {
    setOpenDialogDeleteUserTask(false);
    setOpenDialogCreateUserTask(false);
  };

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(users);
    props.onClose();
  };

  const createUserTask = async () => {
    setLoading(true);
    try {
      if (userId !== "") {
        await taskApi.deleteUser(boardId, taskId, userId);
        users = users.filter((e) => e.id !== userId);
        props.onUpdate(users);
        setUserId("");

        const res = await taskApi.createUser(boardId, taskId, userId);
        users.push(res);
        const colIndex = users.findIndex((e) => e.id === userId);
        task.users.push(users[colIndex]);
        props.onUpdateTask(task, "create", userId);
        props.onUpdate(users);
        setUserId("");
      }
      setLoading(false);
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteUserTask = async (userId) => {
    try {
      if (userId !== "") {
        await taskApi.deleteUser(boardId, taskId, userId);
        users = users.filter((e) => e.id !== userId);
        const newTaskUsers = task.users.filter((e) => e.id !== userId);
        task.users = newTaskUsers;
        props.onUpdateTask(task, "delete", userId);
        props.onUpdate(users);
        setUserId("");
      }
      hideDialog();
    } catch (err) {
      console.log(err);
    }
  };

  const selectUser = (e) => {
    setUserId(e.target.value);
  };

  return (
    <>
      {openDialogCreateUserTask && (
        <Dialog
          open={openDialogCreateUserTask}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure create to this task
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              onClick={createUserTask}
              loading={loading}
              color="primary"
            >
              Yes
            </LoadingButton>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {openDialogDeleteUserTask && (
        <Dialog
          open={openDialogDeleteUserTask}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure delete user from this task
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => deleteUserTask(userIdDelete)}
              color="primary"
            >
              Yes
            </Button>
            <Button onClick={hideDialog} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        open={taskId !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={taskId !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ flex: "1 1 100%" }}
                color="inherit"
                variant="h6"
                component="div"
              >
                Add / Delete Users
              </Typography>
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                margin: "10px 0px 20px 0",
                justifyContent: "space-between",
              }}
            >
              <FormControl size="small" fullWidth sx={{ mr: 2 }}>
                <InputLabel id="label-position">Select User</InputLabel>
                <Select
                  value={userId}
                  sx={{ width: "100%", color: "blue" }}
                  onChange={selectUser}
                  label="Select User"
                >
                  {currentAllUsers.map((user, index) => (
                    <MenuItem key={`${index}-currentUser`} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "150px" }}
                onClick={() => showDialogCreateUserTask()}
              >
                Create
              </Button>
            </Box>
            {users.map((user, index) => (
              <Box
                key={`${index}-users`}
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar alt={user.name} src={user.image} />
                  <Typography
                    sx={{ flex: "1 1 100%" }}
                    color="inherit"
                    variant="h6"
                    component="div"
                  >
                    {user.name}
                  </Typography>
                </Stack>
                {users.length > 1 && (
                  <Button onClick={() => showDialogDeleteUserTask(user.id)}>
                    Delete
                  </Button>
                )}
              </Box>
            ))}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserTaskModal;
