import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import boardTypeApi from "../api/boardTypeApi";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import kpiApi from "../api/kpiApi";
import assets from "../assets/index";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Moment from "moment";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  CardContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

let timer;
const timeout = 500;

const DashboardKPI = () => {
  const theme = useTheme();

  const [showDiv, setShowDiv] = useState(false);
  const [year, setYear] = useState(dayjs(Moment()));

  const [boardTypes, setBoardTypes] = useState([]);
  const [boardType, setBoardType] = useState("");
  const [boardTypeName, setBoardTypeName] = useState("");
  const [finalScore, setFinalScore] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);

  const [kpis, setKpis] = useState([]);

  useEffect(() => {
    const getBoardTypes = async () => {
      try {
        const res = await boardTypeApi.getAll();
        setBoardTypes(res);
      } catch (err) {
        console.log(err);
      }
    };
    getBoardTypes();
  }, []);

  const selectYear = async (e) => {
    const year = e;
    setYear(year);
    if (boardType !== "") {
      timer = setTimeout(async () => {
        try {
          const res = await kpiApi.getAll(boardType, year.format("YYYY"));
          let jsonData = [];
          let finalScore = 0;
          let totalWeight = 0;
          res.forEach(async (kpi) => {
            finalScore = finalScore + parseFloat(kpi.totalScore);
            totalWeight = totalWeight + kpi.percentageWeight;

            jsonData.push({
              additionType: kpi.additionType,
              kpiType: kpi.kpiType,
              indicator: kpi.indicator,
              dataBar: kpi.jsonDone,
              dataBarAcc: kpi.jsonDoneAcc,
              realizationDone: kpi.realizationDone,
              totalScore: kpi.totalScore,
              resultAvg: kpi.resultAvg,
              resultAcc: kpi.resultAcc,
              resultLastNum: kpi.resultLastNum,
            });
          });
          setKpis(jsonData);
          setFinalScore(finalScore);
          setTotalWeight(totalWeight);
        } catch (err) {
          console.log(err);
        }
      }, timeout);
      setShowDiv(true);
    }
  };

  const selectBoardType = async (e) => {
    const boardTypeId = e.target.value;
    const boardTypeIndex = boardTypes.findIndex((e) => e.id === boardTypeId);
    setBoardTypeName(boardTypes[boardTypeIndex].name);
    setBoardType(boardTypeId);
    timer = setTimeout(async () => {
      try {
        const res = await kpiApi.getAll(boardTypeId, year.format("YYYY"));
        let jsonData = [];
        let finalScore = 0;
        let totalWeight = 0;
        res.forEach(async (kpi) => {
          finalScore = finalScore + parseFloat(kpi.totalScore);
          totalWeight = totalWeight + kpi.percentageWeight;

          jsonData.push({
            additionType: kpi.additionType,
            kpiType: kpi.kpiType,
            indicator: kpi.indicator,
            dataBar: kpi.jsonDone,
            dataBarAcc: kpi.jsonDoneAcc,
            realizationDone: kpi.realizationDone,
            totalScore: kpi.totalScore,
            resultAvg: kpi.resultAvg,
            resultAcc: kpi.resultAcc,
            resultLastNum: kpi.resultLastNum,
          });
        });
        setKpis(jsonData);
        setFinalScore((finalScore).toFixed(2));
        setTotalWeight(totalWeight);
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    setShowDiv(true);
  };

  return (
    <>
      <Box
        sx={{
          // width: "100%",
          m: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            p: 2,
            border:
              theme.palette.mode === "dark"
                ? "1px solid " + assets.colors.borderDark
                : "1px solid " + assets.colors.borderLight,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                display: "flex",
                alignItems: "center",
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Select Division
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    padding: "8.5px 0px 8.5px 8.5px",
                  },
                  "& .MuiFormControl-root": {
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                  },
                  "& .MuiOutlinedInput-root": {
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                  },
                }}
              >
                <Select
                  labelId="label-position"
                  name="boardType"
                  fullWidth
                  onChange={(e) => {
                    selectBoardType(e);
                  }}
                  id="boardType"
                  sx={{
                    minWidth: "95px",
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      padding: "8.5px 0px 8.5px 8.5px",
                    },
                    "& .MuiFormControl-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiOutlinedInput-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                    "& .MuiInputBase-root": {
                      background:
                        theme.palette.mode === "dark"
                          ? assets.colors.inputTypeDark
                          : assets.colors.inputTypeLight,
                    },
                  }}
                  className="select-division-kpi"
                >
                  {boardTypes.map((boardType, index) => (
                    <MenuItem key={index} value={boardType.id}>
                      {boardType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={year}
                onChange={(e) => {
                  selectYear(e);
                }}
                dateFormat="yyyy"
                views={["year"]}
                sx={{
                  width: "100%",
                  mr: 1,
                  "& .MuiOutlinedInput-input": {
                    padding: "8.5px 0px 8.5px 8.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    background:
                      theme.palette.mode === "dark"
                        ? assets.colors.inputTypeDark
                        : assets.colors.inputTypeLight,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Paper>
      </Box>
      {showDiv && (
        <Box
          sx={{
            // width: "100%",
            m: 2,
          }}
        >
          <Grid
            container
            spacing={0}
            // sx={{
            // display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            // }}
          >
            <Grid item xs={12} sm={12}>
              <Paper
                sx={{
                  top: "90px",
                  position: "sticky",
                  p: 2,
                  border:
                    theme.palette.mode === "dark"
                      ? "1px solid " + assets.colors.borderDark
                      : "1px solid " + assets.colors.borderLight,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={12} sm={8}>
                    <Paper
                      sx={{ border: "1px solid", borderRadius: "10px", p: 2 }}
                    >
                      <Typography
                        sx={{
                          // textAlign: "center",
                          flex: "1 1 100%",
                          display: "flex",
                          fontWeight: 400,
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.rolesTextDark
                              : assets.colors.rolesTextLight,
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Division KPI
                      </Typography>
                      <Typography
                        sx={{
                          // textAlign: "center",
                          flex: "1 1 100%",
                          display: "flex",
                          fontSize: "28px",
                          fontWeight: 700,
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.rolesTextDark
                              : assets.colors.rolesTextLight,
                        }}
                        id="tableTitle"
                        component="div"
                      >
                        {boardTypeName}
                      </Typography>
                      <Typography
                        sx={{
                          // textAlign: "center",
                          flex: "1 1 100%",
                          display: "flex",
                          fontWeight: 700,
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.rolesTextDark
                              : assets.colors.rolesTextLight,
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Periode {year.format("YYYY")}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Paper
                      sx={{ border: "1px solid", borderRadius: "10px", p: 2 }}
                    >
                      <Typography
                        sx={{
                          textAlign: "center",
                          flex: "1 1 100%",
                          display: "flex",
                          fontWeight: 400,
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.rolesTextDark
                              : assets.colors.rolesTextLight,
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        Final Score
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          flex: "1 1 100%",
                          display: "flex",
                          fontWeight: 700,
                          fontSize: "50px",
                          color:
                            theme.palette.mode === "dark"
                              ? assets.colors.rolesTextDark
                              : assets.colors.rolesTextLight,
                        }}
                        id="tableTitle"
                        component="div"
                      >
                        {finalScore} / {totalWeight}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
              {kpis.length !== 0 &&
                kpis.map((item, index) => (
                  <Paper
                    sx={{
                      mt: 2,
                      p: 2,
                      border:
                        theme.palette.mode === "dark"
                          ? "1px solid " + assets.colors.borderDark
                          : "1px solid " + assets.colors.borderLight,
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item xs={12} sm={4}>
                        <Typography
                          sx={{
                            textAlign: "center",
                            flex: "1 1 100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontWeight: 700,
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.rolesTextDark
                                : assets.colors.rolesTextLight,
                          }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          {item.indicator}
                        </Typography>
                        <Typography
                          sx={{
                            flex: "1 1 100%",
                            display: "flex",
                            mt: 1,
                            fontWeight: 700,
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.rolesTextDark
                                : assets.colors.rolesTextLight,
                          }}
                          variant="h4"
                          id="tableTitle"
                          component="div"
                        >
                          {item.additionType === 0 ? item.resultAcc : item.additionType === 1 ? item.resultLastNum : item.resultAvg}
                        </Typography>
                        <Typography
                          sx={{
                            flex: "1 1 100%",
                            display: "flex",
                            alignItems: "center",
                            fontWeight: 400,
                            justifyContent: "center",
                            color:
                              theme.palette.mode === "dark"
                                ? assets.colors.rolesTextDark
                                : assets.colors.rolesTextLight,
                          }}
                          variant="h6"
                          id="tableTitle"
                          component="div"
                        >
                          {item.kpiType === 0 ? "Minimize" : "Maximize"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <BarChart
                          width={800}
                          height={300}
                          series={[
                            {
                              data:
                                item.additionType === 0
                                  ? item.dataBarAcc
                                  : item.dataBar,
                            },
                          ]}
                          xAxis={[
                            {
                              data: [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec",
                              ],
                              scaleType: "band",
                            },
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DashboardKPI;
