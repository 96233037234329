import {
    Backdrop,
    Fade,
    Modal,
    Box,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
  } from "@mui/material";
  import React, { useEffect, useRef, useState } from "react";
  import { useSelector } from "react-redux";
  import { Cancel } from "@mui/icons-material";
  import speedIconApi from "../../api/speedIconApi";
  import EmojiPicker from "./EmojiPicker";
  let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "70%",
    color: "#999",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "70%",
    color: "#999",
  };
}
  
  let timer;
  const timeout = 500;
  let isModalClosed = false;
  
  const SpeedIconModal = (props) => {
    const [speedIcon, setSpeedIcon] = useState(props.speedIcon);
    const [speed, setSpeed] = useState("");
    const [icon, setIcon] = useState("📃");
  
    useEffect(() => {
      setSpeedIcon(props.speedIcon);
      setIcon(props.speedIcon !== undefined ? props.speedIcon.icon : "");
      setSpeed(props.speedIcon !== undefined ? props.speedIcon.speed : "");
      if (props.speedIcon !== undefined) {
        isModalClosed = false;
      }
    }, [props.speedIcon]);
  
    const onClose = () => {
      isModalClosed = true;
      props.onUpdate(speedIcon);
      props.onClose();
    };

    const onIconChange = async (newIcon) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        try {
          await speedIconApi.update(speedIcon.id, { icon: newIcon });
        } catch (err) {
          console.log(err);
        }
      }, timeout);
      speedIcon.icon = newIcon;
      setIcon(newIcon);
      props.onUpdate(speedIcon);
    };
  
    const updateSpeed = async (e) => {
      clearTimeout(timer);
      const newSpeed = e.target.value;
      timer = setTimeout(async () => {
        try {
          await speedIconApi.update(speedIcon.id, { speed: newSpeed });
        } catch (err) {
          console.log(err);
        }
      }, timeout);
      speedIcon.speed = newSpeed;
      setSpeed(newSpeed);
      props.onUpdate(speedIcon);
    };
  
    return (
      <>
        <Modal
          open={speedIcon !== undefined}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={speedIcon !== undefined}>
            <Box sx={modalStyle}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <Cancel
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    isModalClosed = true;
                    props.onUpdate(speedIcon);
                    props.onClose();
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: "0rem 2rem",
                }}
              >
                <Box>
                  Icon
                  <EmojiPicker icon={icon} onChange={onIconChange} />
                </Box>
                <Box sx={{mt:1,}}>
                  Speed
                  <TextField
                  type="number"
                    value={speed}
                    onChange={updateSpeed}
                    placeholder="Untitled"
                    variant="outlined"
                    fullWidth
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-input": { padding: 0 },
                      "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                      "& .MuiOutlinedInput-root": {
                        fontSize: "1.5rem",
                        fontWeight: "700",
                      },
                      marginBottom: "10px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  };
  
  export default SpeedIconModal;
  