import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import userApi from "../api/userApi";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import kpiApi from "../api/kpiApi";
import assets from "../assets/index";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Moment from "moment";
import dayjs from "dayjs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  MenuItem,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  CardContent,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

let timer;
const timeout = 500;

const DashboardHr = () => {
  const theme = useTheme();

  const [barGender, setBarGender] = useState([]);
  const [bloods, setBloods] = useState([]);
  const [lastEducations, setLastEducations] = useState([]);
  const [workTypes, setWorkTypes] = useState([]);
  const [selfStatuses, setSelfStatuses] = useState([]);
  const [ageValues, setAgeValues] = useState([]);
  const [ageColors, setAgeColors] = useState([]);
  const [ageLabels, setAgeLabels] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const jsonData = [];
        const res = await userApi.getUsers();

        let jsonWorkTypes = [];
        let jsonAges = [];
        let jsonLastEducations = [];
        let jsonBloods = [];
        let jsonSelfStatuses = [];
        let totalWorkTypes = 0;
        let totalBloods = 0;
        let totalSelfStatuses = 0;
        let totalLastEducations = 0;

        let totalMale = 0;
        let totalFemale = 0;
        let totalOther = 0;
        res.forEach((val, idx) => {
          if (val.gender === "male") {
            totalMale = totalMale + 1;
          } else if (val.gender === "female") {
            totalFemale = totalFemale + 1;
          }

          if (val.birth !== undefined) {
            let year = dayjs(val.birth).format("YYYY");
            const agesCol = jsonAges.findIndex((e) => e.label === year);
            if (agesCol === -1) {
              jsonAges.push({
                id: idx,
                value: 1,
                label: year,
              });
            } else {
              jsonAges[agesCol].value = jsonAges[agesCol].value + 1;
            }
          }

          if (val.employeeType !== undefined) {
            totalWorkTypes = totalWorkTypes + 1;
            const workTypeCol = jsonWorkTypes.findIndex(
              (e) => e.label === val.employeeType.name
            );
            if (workTypeCol === -1) {
              jsonWorkTypes.push({
                id: idx,
                value: 1,
                label: val.employeeType.name,
                //   color: val.color === "" ? "red" : val.color,
              });
            } else {
              jsonWorkTypes[workTypeCol].value =
                jsonWorkTypes[workTypeCol].value + 1;
            }
          }
          if (val.blood !== undefined && val.blood !== "") {
            totalBloods = totalBloods + 1;
            const bloodCol = jsonBloods.findIndex((e) => e.label === val.blood);
            if (bloodCol === -1) {
              jsonBloods.push({
                id: idx,
                value: 1,
                label: val.blood,
                //   color: val.color === "" ? "red" : val.color,
              });
            } else {
              jsonBloods[bloodCol].value = jsonBloods[bloodCol].value + 1;
            }
          }
          if (val.selfStatus !== undefined && val.selfStatus !== "") {
            totalSelfStatuses = totalSelfStatuses + 1;
            const selfStatusCol = jsonSelfStatuses.findIndex(
              (e) => e.label === val.selfStatus
            );
            if (selfStatusCol === -1) {
              jsonSelfStatuses.push({
                id: idx,
                value: 1,
                label: val.selfStatus,
                //   color: val.color === "" ? "red" : val.color,
              });
            } else {
              jsonSelfStatuses[selfStatusCol].value =
                jsonSelfStatuses[selfStatusCol].value + 1;
            }
          }
          if (val.lastEducation !== undefined && val.lastEducation !== "") {
            totalLastEducations = totalLastEducations + 1;
            const lastEducationCol = jsonLastEducations.findIndex(
              (e) => e.label === val.lastEducation
            );
            if (lastEducationCol === -1) {
              jsonLastEducations.push({
                id: idx,
                value: 1,
                label: val.lastEducation,
                //   color: val.color === "" ? "red" : val.color,
              });
            } else {
              jsonLastEducations[lastEducationCol].value =
                jsonLastEducations[lastEducationCol].value + 1;
            }
          }
        });
        let sortedJsonAges = jsonAges.sort(
          (a, b) => new Date(a.label) - new Date(b.label)
        );
        const jsonWorkTypesAll = [];
        jsonWorkTypes.forEach((value, idx) => {
          value["percentage"] = (value.value / totalWorkTypes) * 100;
          jsonWorkTypesAll.push(value);
        });
        const jsonLastEducationsAll = [];
        jsonLastEducations.forEach((value, idx) => {
          value["percentage"] = (value.value / totalLastEducations) * 100;
          jsonLastEducationsAll.push(value);
        });
        const jsonBloodsAll = [];
        jsonBloods.forEach((value, idx) => {
          value["percentage"] = (value.value / totalBloods) * 100;
          jsonBloodsAll.push(value);
        });
        const jsonSelfStatusesAll = [];
        jsonSelfStatuses.forEach((value, idx) => {
          value["percentage"] = (value.value / totalSelfStatuses) * 100;
          jsonSelfStatusesAll.push(value);
        });
        let percentageMale = (totalMale / (totalMale + totalFemale)) * 100;
        let percentageFemale = (totalFemale / (totalMale + totalFemale)) * 100;
        setBarGender([percentageMale, percentageFemale]);
        setWorkTypes(jsonWorkTypesAll);
        setLastEducations(jsonLastEducationsAll);
        setBloods(jsonBloodsAll);
        setSelfStatuses(jsonSelfStatusesAll);
        const ageLabels = [];
        const ageValues = [];
        sortedJsonAges.filter((e) => ageLabels.push(e.label));
        sortedJsonAges.filter((e) => ageValues.push(e.value));
        setAgeValues(ageValues);
        setAgeLabels(ageLabels);
        const minVal = Math.min(...ageValues);
        const maxVal = Math.max(...ageValues);
        const ageColors = ageValues.map((val) => {
          if (val === minVal) {
            return "red";
          } else if (val === maxVal) {
            return "green";
          } else {
            return null; // non-max and non-min values take default color of series
          }
        });
        setAgeColors(ageColors);
      } catch (err) {
        console.log(err);
      }
    };
    getUsers();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Age Ratio
            </Typography>
            <BarChart
              sx={{ width: "100%" }}
              height={300}
              series={[
                {
                  data: ageValues,
                },
              ]}
              xAxis={[
                {
                  data: ageLabels,
                  scaleType: "band",
                  colorMap: {
                    type: "ordinal",
                    values: ageLabels,
                    colors: ageColors,
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Male and Female Ratio
            </Typography>
            <BarChart
              sx={{ width: "100%" }}
              height={300}
              series={[
                {
                  data: barGender,
                },
              ]}
              xAxis={[
                {
                  data: ["Male", "Female"],
                  scaleType: "band",
                  colorMap: {
                    type: "ordinal",
                    values: ["Male", "Female"],
                    colors: ["blue","pink"],
                  },
                },
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Work Type
            </Typography>
            <PieChart
            margin={{ top: 0, bottom: 50, left: 0, right: 0 }}
            slotProps={{
              legend: {
                direction: "row",
                position: {
                  vertical: "bottom",
                  horizontal: "middle",
                },
                padding: 0,
              },
            }}
              series={[
                {
                  arcLabel: (item) =>
                    `${item.percentage && item.percentage.toFixed(2)}%`,
                  arcLabelMinAngle: 25,
                  data: workTypes,
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Blood
            </Typography>
            <PieChart
            margin={{ top: 0, bottom: 50, left: 0, right: 0 }}
            slotProps={{
              legend: {
                direction: "row",
                position: {
                  vertical: "bottom",
                  horizontal: "middle",
                },
                padding: 0,
              },
            }}
              series={[
                {
                  arcLabel: (item) =>
                    `${item.percentage && item.percentage.toFixed(2)}%`,
                  arcLabelMinAngle: 25,
                  data: bloods,
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Last Education
            </Typography>
            <PieChart
            margin={{ top: 0, bottom: 50, left: 0, right: 0 }}
            slotProps={{
              legend: {
                direction: "row",
                position: {
                  vertical: "bottom",
                  horizontal: "middle",
                },
                padding: 0,
              },
            }}
              series={[
                {
                  arcLabel: (item) =>
                    `${item.percentage && item.percentage.toFixed(2)}%`,
                  arcLabelMinAngle: 25,
                  data: lastEducations,
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Paper sx={{ p: 2 }}>
            <Typography
              sx={{
                flex: "1 1 100%",
                fontWeight: 700,
                color:
                  theme.palette.mode === "dark"
                    ? assets.colors.rolesTextDark
                    : assets.colors.rolesTextLight,
              }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Marital status
            </Typography>
            <PieChart
            margin={{ top: 0, bottom: 50, left: 0, right: 0 }}
            slotProps={{
              legend: {
                direction: "row",
                position: {
                  vertical: "bottom",
                  horizontal: "middle",
                },
                padding: 0,
              },
            }}
              series={[
                {
                  arcLabel: (item) =>
                    `${item.percentage && item.percentage.toFixed(2)}%`,
                  arcLabelMinAngle: 25,
                  data: selfStatuses,
                  highlightScope: {
                    faded: "global",
                    highlighted: "item",
                  },
                  faded: {
                    innerRadius: 30,
                    additionalRadius: -30,
                    color: "gray",
                  },
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                },
              }}
              height={300}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardHr;
