import axiosClient from './axiosClient'

const noteApi = {
  create: params => axiosClient.post('notes', params),
  getAll: () => axiosClient.get('notes'),
  getNotification: (userId) => axiosClient.get(`notes/notification/${userId}`),
  updateReadCount: (userId) => axiosClient.get(`notes/updateReadCount/${userId}`),
  getOne: (id,noteId) => axiosClient.get(`notes/${id}/${noteId}`),
  delete: (id) => axiosClient.delete(`notes/${id}`),
  update: (id, params) => axiosClient.put(`notes/${id}`, params),
}

export default noteApi