import {
  Backdrop,
  Fade,
  Modal,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Cancel } from "@mui/icons-material";
import roleApi from "../../api/roleApi";

let modalStyle = "";

if (window.innerWidth > 768) {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "50%",
    color: "#999",
  };
} else {
  modalStyle = {
    outline: "none",
    position: "absolute",
    top: "50%",
    left: "50%",
    overflowY: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "0px solid #000",
    boxShadow: 24,
    p: 1,
    height: "50%",
    color: "#999",
  };
}

let timer;
const timeout = 500;
let isModalClosed = false;

const RoleModal = (props) => {
  const [role, setRole] = useState(props.role);
  const [name, setName] = useState("");
  const [isManage, setIsManage] = useState("");
  const [typeManage, setTypeManage] = useState("");
  const [toggleTypeManage, setToggleTypeManage] = useState(false);

  useEffect(() => {
    setRole(props.role);
    setName(props.role !== undefined ? props.role.name : "");
    setIsManage(props.role !== undefined ? props.role.isManage : "");
    setTypeManage(props.role !== undefined ? props.role.typeManage : "");
    if (props.role !== undefined) {
      isModalClosed = false;
      if (props.role.isManage === 1 || props.role.isManage === "1") {
        setToggleTypeManage(true);
      } else {
        setToggleTypeManage(false);
      }
    }
  }, [props.role]);

  const onClose = () => {
    isModalClosed = true;
    props.onUpdate(role);
    props.onClose();
  };

  const updateName = async (e) => {
    clearTimeout(timer);
    const newName = e.target.value;
    timer = setTimeout(async () => {
      try {
        await roleApi.update(role.id, { name: newName });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    role.name = newName;
    setName(newName);
    props.onUpdate(role);
  };

  const updateIsManage = async (e) => {
    clearTimeout(timer);
    if (e.target.value === "1") {
      setToggleTypeManage(true);
    } else {
      setToggleTypeManage(false);
    }
    const newIsManage = e.target.value;
    timer = setTimeout(async () => {
      try {
        await roleApi.update(role.id, { isManage: newIsManage });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    role.isManage = newIsManage;
    setIsManage(newIsManage);
    props.onUpdate(role);
  };

  const updateTypeManage = async (e) => {
    clearTimeout(timer);
    const newTypeManage = e.target.value;
    timer = setTimeout(async () => {
      try {
        await roleApi.update(role.id, { typeManage: newTypeManage });
      } catch (err) {
        console.log(err);
      }
    }, timeout);
    role.typeManage = newTypeManage;
    setTypeManage(newTypeManage);
    props.onUpdate(role);
  };

  return (
    <>
      <Modal
        open={role !== undefined}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={role !== undefined}>
          <Box sx={modalStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Cancel
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  isModalClosed = true;
                  props.onUpdate(role);
                  props.onClose();
                }}
              />
            </Box>
            <Box
              sx={{
                // display: "flex",
                // justifyContent: "space-between",
                p: 2,
              }}
            >
              <Box>
                Name
                <TextField
                  value={name}
                  onChange={updateName}
                  placeholder="Untitled"
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { padding: 0 },
                    "& .MuiOutlinedInput-notchedOutline": { border: "unset " },
                    "& .MuiOutlinedInput-root": {
                      fontSize: "1.5rem",
                      fontWeight: "700",
                    },
                    marginBottom: "10px",
                  }}
                />
                <Box>
                  Is Manage
                  <br />
                  <FormControl size="small" sx={{ marginTop: "10px" }}>
                    <Select
                      labelId="demo-simple-select-label"
                      value={isManage}
                      id="demo-simple-select"
                      sx={{ width: "300px" }}
                      onChange={updateIsManage}
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {toggleTypeManage && (
                  <Box sx={{ mt: 1 }}>
                    Type Manage
                    <br />
                    <FormControl size="small" sx={{ marginTop: "10px" }}>
                      <Select
                        labelId="demo-simple-select-label"
                        value={typeManage}
                        id="demo-simple-select"
                        sx={{ width: "300px" }}
                        onChange={updateTypeManage}
                      >
                        <MenuItem value="0">All Board</MenuItem>
                        <MenuItem value="1">Only the board is created</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RoleModal;
